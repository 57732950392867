import React, { useEffect, useRef } from "react";
import logo1 from "../../src/images/Features/f1.png";
import logo2 from "../../src/images/Features/f2.png";
import logo3 from "../../src/images/Features/f3.png";
import logo4 from "../../src/images/Features/f4.png";
import logo5 from "../../src/images/Features/f5.png";
import logo6 from "../../src/images/Features/f6.png";
import image1 from "../../src/images/Features/fb11.jpg";
import image2 from "../../src/images/Features/fb22.jpg";
import { inView, useAnimation, useInView, motion } from "framer-motion";
import teacherManual from "../../src/images/teacherManual.pdf";
import activitiesAssessments from "../../src/images/activitiesAssessments.pdf";
import pptLessons from "../../src/images/pptLessons.pdf";
const Features = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControlls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControlls.start("visible");
    }
  }, [isInView]);
  const skillBuilderComponents = [
    {
      title: "Teacher Manual - Sample",
      description:
        "Includes an Introduction to each of the Reading Comprehension Skill Categories; a summary of each of the lessons and student activities; alignment to the Alberta Grade 6 Curriculum for each Reading Skill Category Lesson package; suggested content from Reading Comprehension Success Blackline Master Packages for additional practice in each reading comprehension skill category; Answer Keys for Student Skills Check, Student Journal Activities; Student Unit Test; and Student Post-test.",
      icon: logo3,
      pdfLink: teacherManual,
    },
    {
      title: "Student Journal",
      description:
        "A PDF of student reading activities and writing activities to be completed after each lesson or groups of lessons within the PowerPoint Presentations. These activities can be assigned to students individually or in small groups to allow for discussion and critical thinking participation. You may choose to use any of the activities as formative or summative assignments. The PDFs can be printed and given to students or shared digitally with students to work online.",
      icon: logo2,
    },
    {
      title: "Assessment Section - Sample",
      description:
        "Includes PDFs of Student Skill Check, the Student Unit Tests for each of the 5 Reading Skill Categories, and the Student Post-Test. Please note that Answer Keys for Student Skills Check, Student Unit Tests; and Student Post-test are only available in the Teacher Manual.",
      icon: logo1,
      pdfLink: activitiesAssessments,
    },
    {
      title: "PowerPoint Presentation Lessons - Sample",
      description:
        "Includes 5 mini-lesson packages on each of the Reading Comprehension Skill Categories. These can be modified to suit your classroom needs. These can be taught in any order that works best for you. The units can be taught within a 3-to-4-week time frame, one unit taught every month, or units taught as required throughout the year. Please infuse your own content and add in novel studies, short story units, poetry units etc. to demonstrate the significance and application of reading comprehension",
      icon: logo4,
      pdfLink: pptLessons,
    },
  ];

  return (
    <>
      <div className="w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
      <div className="max-w-screen overflow-x-hidden font-poppins">
        {/* features section */}
        {width < 631 ? (
          <>
                      <section className="text-black">
              <div className="container max-w-xl p-6 py-12 mx-auto space-y-8 lg:px-8 lg:max-w-7xl">
              <motion.h2
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="text-3xl font-bold tracking-tight text-center sm:text-5xl"
    >
      {/* Looping Animation for "NEW" */}
      <motion.span
        animate={{
          // Example 1: Pulsate (scale up and down)
          scale: [1, 1.2, 1],
          
          // Example 2: Change color
          color: ["#FF0000", "#00FF00", "#0000FF"],
        }}
        transition={{
          duration: 2,           // Total duration of one loop
          repeat: Infinity,      // Repeat indefinitely
          repeatType: "loop",    // Loop the animation
          ease: "easeInOut",     // Easing function
        }}
        className="mr-2" // Optional: Add some margin to the right
      >
        NEW
      </motion.span>
      Skill Builder Teacher Packs
    </motion.h2>
                <div className="space-y-8">
                  {skillBuilderComponents.map((component, index) => (
                    <div key={index} className="flex items-start">
                      <div className="flex-shrink-0">
                        <img
                          src={component.icon}
                          alt={component.title}
                          className="w-12 h-12 mr-4"
                        />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold">
                          <a
                            href={component.pdfLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {component.title}
                          </a>
                        </h3>
                        <p className="mt-2 text-base whitespace-pre-wrap">
                          {component.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          
            <section className=" text-black">
              <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl ">
                    Reading Comprehension Assessment Tool (RCAT)
                  </h2>
                  <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">
                    Access our comprehensive suite of digital and printed assessments specifically designed to enhance reading comprehension skills from grades 3 to 12.
                  </p>
                </div>
                <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                  <div>
                    <h3 className="text-2xl font-bold tracking-tight sm:text-3xl ">
                      Comprehensive Digital Assessments
                    </h3>
                    <p className="mt-3 text-lg ">
                      Dive into our digital platform offering secure, grade-specific assessments that support educators in tracking and improving student reading skills.
                    </p>
                    <div className="mt-12 space-y-12">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                            <img src={logo1} alt="Digital Assessments" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                            Tailored to Each Grade
                          </h4>
                          <p className="mt-2 ">
                            Our assessments are tailored to meet the specific needs of each grade level, ensuring relevant and effective evaluation.
                          </p>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={logo2} alt="Printed Materials" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                            Printed Review Materials
                          </h4>
                          <p className="mt-2 ">
                            Complement the digital tools with printed materials that focus on areas needing more attention, enhancing learning and retention.
                          </p>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={logo3} alt="Professional Development" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                            Teacher Workshops
                          </h4>
                          <p className="mt-2 ">
                            Enhance teaching skills with professional development workshops focused on strategies for teaching reading comprehension.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div aria-hidden="true" className="mt-10 lg:mt-0">
                      <img
                        src={image1}
                        alt="RCAT Digital Platform"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                    <div className="lg:col-start-2">
                      <h3 className="text-2xl font-bold tracking-tight sm:text-3xl ">
                        Reading Comprehension Success
                      </h3>
                      <p className="mt-3 text-lg ">
                        Support students' reading success with our structured and comprehensive reading comprehension program available for grades 3 to 12.
                      </p>
                      <div className="mt-12 space-y-12">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={logo4} alt="Student Workbooks" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                              Student Workbooks
                            </h4>
                            <p className="mt-2 ">
                              Provide students with workbooks that offer extensive practice and skill-based assessments to improve reading comprehension.
                            </p>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={logo5} alt="Online Tutoring" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                              Online Tutoring Sessions
                            </h4>
                            <p className="mt-2 ">
                              Access personalized tutoring sessions to address individual learning gaps and strengthen reading skills effectively.
                            </p>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={logo6} alt="Continuous Innovation" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                              Continuous Updates and Innovation
                            </h4>
                            <p className="mt-2 ">
                              Stay updated with the latest educational strategies and technologies to continually enhance the learning experience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <motion.div
                      ref={ref}
                      variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.15 }}
                      className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1"
                    >
                      <img
                        src={image2}
                        alt="Reading Comprehension Tools"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
          <section className="text-black">
              <div className="container max-w-xl p-6 py-12 mx-auto space-y-8 lg:px-8 lg:max-w-7xl">
              <motion.h2
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="text-3xl font-bold tracking-tight text-center sm:text-5xl"
    >
      {/* Looping Animation for "NEW" */}
      <motion.span
        animate={{
          // Example 1: Pulsate (scale up and down)
          scale: [1, 1.2, 1],
          
          // Example 2: Change color
          color: ["#FF0000", "#00FF00", "#0000FF"],
        }}
        transition={{
          duration: 2,           // Total duration of one loop
          repeat: Infinity,      // Repeat indefinitely
          repeatType: "loop",    // Loop the animation
          ease: "easeInOut",     // Easing function
        }}
        className="mr-2" // Optional: Add some margin to the right
      >
        NEW
      </motion.span>
      Skill Builder Teacher Packs
    </motion.h2>
                <div className="space-y-8">
                  {skillBuilderComponents.map((component, index) => (
                    <div key={index} className="flex items-start">
                      <div className="flex-shrink-0">
                        <img
                          src={component.icon}
                          alt={component.title}
                          className="w-12 h-12 mr-4"
                        />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold">
                          <a
                            href={component.pdfLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {component.title}
                          </a>
                        </h3>
                        <p className="mt-2 text-base whitespace-pre-wrap">
                          {component.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section className=" text-black">
              <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl">
                    Reading Comprehension Assessment Tool (RCAT)
                  </h2>
                  <p className="max-w-3xl mx-auto mt-4 text-xl text-center">
                  Empowering teachers with reliable tools to assess and improve reading comprehension skills from grade 3 to grade 12.
                  </p>
                </div>
                <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                  <div>
                    <h3 className="text-2xl font-bold tracking-tight sm:text-3xl">
                      Tools for Effective Learning
                    </h3>
                    <p className="mt-3 text-lg">
                      Offering a blend of digital and printed resources to enhance the teaching and learning of reading comprehension skills effectively.
                    </p>
                    <div className="mt-12 space-y-12">
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.15 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                            <img src={logo1} alt="logo1" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6">
                          Advanced Formative Assessments
                          </h4>
                          <p className="mt-2">
                            Leverage our advanced digital tools to accurately assess reading comprehension skills and identify specific learning gaps.
                          </p>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.35 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={logo2} alt="logo2" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6">
                            Engaging Learning Materials
                          </h4>
                          <p className="mt-2">
                            Use our comprehensive printed materials to reinforce key reading skills and provide additional practice.
                          </p>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.55 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={logo3} alt="logo3" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6">
                            Professional Development for Educators
                          </h4>
                          <p className="mt-2">
                            Empower educators with targeted workshops and training sessions designed to improve teaching strategies and classroom effectiveness in reading comprehension.
                          </p>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                  <div ref={ref}>
                    <motion.div
                      variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.25 }}
                      aria-hidden="true"
                      className="mt-10 lg:mt-0"
                    >
                      <img
                        src={image1}
                        alt="Digital and Printed Reading Tools"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                      />
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                    <div className="lg:col-start-2">
                      <h3 className="text-2xl font-bold tracking-tight sm:text-3xl">
                        Personalized Learning Pathways
                      </h3>
                      <p className="mt-3 text-lg">
                        Create individualized learning experiences that cater to the unique needs of each student, enhancing their ability to understand and retain information.
                      </p>
                      <div className="mt-12 space-y-12">
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.15 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={logo4} alt="Student Engagement" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6">
                              Interactive and Adaptive Learning
                            </h4>
                            <p className="mt-2">
                              Utilize technology-driven solutions to adapt to student needs in real-time, ensuring a dynamic and engaging learning process.
                            </p>
                          </div>
                        </motion.div>
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.35 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={logo5} alt="Data-Driven Insights" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6">
                              Data-Driven Insights
                            </h4>
                            <p className="mt-2">
                              Harness the power of analytics to track progress, identify trends, and make informed decisions to optimize learning outcomes.
                            </p>
                          </div>
                        </motion.div>
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.55 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={logo6} alt="Continuous Innovation" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6">
                              Continuous Educational Innovation
                            </h4>
                            <p className="mt-2">
                              Stay at the forefront of educational practices with ongoing updates and enhancements to our tools and methodologies.
                            </p>
                          </div>
                        </motion.div>
                      </div>
                    </div>
                    <motion.div
                      ref={ref}
                      variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.15 }}
                      className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1"
                    >
                      <img
                        src={image2}
                        alt="Reading Comprehension Interface"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        {/* end */}
      </div>
      <div className="flex justify-end ml-[30%] w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
    </>
  );
};

export default Features;

