import React from 'react'
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="overflow-x-hidden max-w-screen font-poppins">
      {/* footer top section */}
      <div className="sm:flex justify-between py-10 px-8 grid bg-gray-900">
        <div className="text-white">
          <h1 className="w-full text-3xl font-bold cursor-pointer text-blue-400">
            Edu-Best
          </h1>
          <p className="py-4">
            Edu-Best provides comprehensive tools for enhancing reading comprehension through our Reading Comprehension Assessment Tool (RCAT), designed for educators to improve student outcomes effectively.
          </p>
          <div className="flex md:w-[75%] justify-between my-6 text-blue-200">
        
            <a href="https://www.linkedin.com/in/brigitta-goerres-96623222/" >
            <FaLinkedin size={30} className="cursor-pointer " />
                        </a>
          </div>
        </div>
        <div className="text-white sm:w-[900px] sm:ml-[18%] sm:mr-[12%] sm:pt-0">
          <div>
            <h1 className="sm:w-[30rem] md:text-4xl sm:text-3xl text-center text-2xl font-bold py-2">
              Enhance Your Educational Impact
            </h1>
      
          </div>
          
        </div>
      </div>
      {/* footer top section end */}
      <div className="sm:mx-auto pt-10 pb-8 sm:grid flex-row lg:grid-cols-3 w-screen gap-8 text-gray-300 bg-gray-800 ">
        <div className="lg:col-span-3 sm:mr-20  flex justify-between max-w-screen sm:ml-20 ml-[12px] mb-4 sm:mb-0 ">

          <div className="pr-4">
            <h6 className="font-medium text-blue-400">Support</h6>
            <ul>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                Help Center
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                FAQs
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                Contact Us
              </li>
            </ul>
          </div>
          <div className="pr-4">
            <h6 className="font-medium text-blue-400">Company</h6>
            <ul>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                About Us
              </li>
        
              <li className="py-2 text-sm cursor-pointer font-semibold">
                Press
              </li>
            </ul>
          </div>
        </div>
        <div className="h-[1px] bg-gray-100/20 w-[100rem] ml-[-20rem]"></div>
        <p className='pt-4 text-gray-200/40 text-sm flex justify-center text-center'>© 2024 Edu-Best. All Rights Reserved.</p>
      </div>
      {/* footer bottom section with links end */}
    </div>
  );
}

export default Footer;
