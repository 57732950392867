import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const CallToAction = () => {
    const { innerWidth: Width } = window;
    const ref = useRef();
    const isInView = useInView(ref)
    const mainControls = useAnimation(isInView);
    const navigate = useNavigate();

    const redirectToContact = () => {
        navigate('/contact');
    };

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible")
        }
    }, [isInView, mainControls])

    return (
        <div>
            <div className="w-[70%]  h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
            <>
                <section className="py-20 lg:py-[120px] bg-gray-100/50 font-poppins">
                    <div className="container mx-auto">
                        <div className="bg-blue-800 relative z-10 sm:-mt-10 sm:-mb-10 overflow-x-hidden rounded py-12 px-8 md:p-[70px]">
                            <div className="-mx-4 flex flex-wrap items-center">
                                <motion.div
                                    ref={ref}
                                    variants={{
                                        visible: { opacity: 1, x: 0 },
                                        hidden: { opacity: 0, x: -75 },
                                    }}
                                    initial="hidden"
                                    animate={mainControls}
                                    transition={{ duration: 0.4, delay: 0.2 }}
                                    className="w-full px-4 lg:w-1/2"
                                >
                                    <span className="mb-2 text-base font-semibold text-white">
                                        Empowering Educators to Enhance Learning
                                    </span>
                                    <h2 className="mb-6 text-3xl font-bold leading-tight text-white sm:mb-8 sm:text-[38px] lg:mb-0">
                                        Enhance reading comprehension with our RCAT tool <br className="xs:block hidden" />
                                        and specialized resources
                                    </h2>
                                </motion.div>
                                <div className="w-full px-4 lg:w-1/2">
                                    <div className="flex sm:flex-wrap sm:justify-end">
                                        <motion.div
                                            ref={ref}
                                            variants={{
                                                visible: { opacity: 1, x: 0 },
                                                hidden: { opacity: 0, x: 75 },
                                            }}
                                            initial="hidden"
                                            animate={mainControls}
                                            transition={{ duration: 0.4, delay: 0.2 }}
                                        >
                                            <button
                                                className="hover:bg-blue-500 my-1 mr-4 inline-block rounded bg-white bg-opacity-[15%] py-4 px-6 text-base font-medium text-white transition hover:bg-opacity-100 md:px-9 lg:px-6 xl:px-9"
                                            >
                                                Explore Workshops (Coming Soon)
                                            </button>
                                        </motion.div>
                                        <motion.div
                                            ref={ref}
                                            variants={{
                                                visible: { opacity: 1, x: 0 },
                                                hidden: { opacity: 0, x: 75 },
                                            }}
                                            initial="hidden"
                                            animate={mainControls}
                                            transition={{ duration: 0.4, delay: 0.5 }}
                                        >
                                            <button
                                                className="my-1 inline-block rounded bg-[#1c9b7b] py-4 px-6 text-base font-medium text-white transition hover:bg-opacity-90 md:px-9 lg:px-6 xl:px-9"
                                                onClick={redirectToContact}
                                            >
                                                Get Started with RCAT
                                            </button>

                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                            {/* Additional SVG and other decorative elements can remain as is */}
                        </div>
                    </div>
                </section>
            </>
            <div className="flex justify-end ml-[30%] w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
        </div>
    );
};

export default CallToAction;
