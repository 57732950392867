import React, { useEffect, useRef } from "react";
import Navbar from "../../Navigation/Navbar"; // Adjust the import path based on your project structure
import Footer from "../../Footer/Footer"; // Adjust the import path based on your project structure
import { useAnimation, useInView, motion } from "framer-motion";

const PrivacyPolicy = () => {
  const ref = useRef();
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div>
      <Navbar />
      <div className="max-w-screen-xl mx-auto overflow-hidden font-poppins">
        <div className="bg-gradient-to-b from-gray-100/10 to-gray-200/50 pt-20">
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: -75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            className="text-center"
          >
            <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-emerald-500 font-extrabold text-4xl">
              Edu-Best Privacy Policy
            </h1>
          </motion.div>
          <div className="px-5 sm:px-20 py-10 text-left">
            <p>
              Edu-Best Educational Resources and Services operates a website at{" "}
              <a href="https://edu-best.com/" className="text-blue-500">
                https://edu-best.com/
              </a>{" "}
              and provides a Reading Comprehension Assessment Tool (RCAT) as an online service for students and teachers. The RCAT may be used to provide valid and reliable assessments for diagnosing Reading Comprehension skills for students in grades three through twelve. Collectively, these are called the EDU-BEST Service as provided by Edu-Best Educational Resources and Services (referred to as “Edu-Best”, “we”, “us” or other similar terms in this Privacy Policy). To better protect your privacy, we provide this Privacy Policy to explain our information practices relating to the Edu-Best Service. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings assigned to them in our Terms of Service.
            </p>
            <p>
              Personal privacy is a very serious matter and we are particularly committed to protecting the privacy of minors. For that reason, we will refuse to open an account for a person under the age of majority in your state or province of residence without parental or guardian consent and we reserve the right to verify the existence of that consent. If you are under the age of majority in your state or province of residence, please do not attempt to register for membership on or send any information about yourself to us without consent. If we learn that information from a person under the age of majority in your jurisdictional region of residence has been submitted to us without proper consent, we will delete that account and information as soon as possible.
            </p>
            <p>
              Edu-Best cooperates with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), to protect the property and rights of Edu-Best or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable activity.
            </p>
            <p>
              As used in this Privacy Policy, the terms “using” and “processing” information include using cookies on a computer, subjecting the information to statistical or other analysis and using or handling information in any way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and transferring information within our organization or among our affiliates within Canada, the United States or internationally.
            </p>
            <h2 className="font-bold text-2xl mt-10">Acceptance and Notification of Changes</h2>
            <p>
              WHEN OUR WEBSITE IS ACCESSED OR USED BY A STUDENT (WITH PARENTAL CONSENT, IF REQUIRED), THE STUDENT OR THE PARENT/GUARDIAN (IF APPLICABLE), FOR HIM/HERSELF (AND ON BEHALF OF THE STUDENT, IF APPLICABLE) AGREES TO THIS PRIVACY POLICY, AND ANY FUTURE AMENDMENTS THEREOF. IF A VISITOR, MEMBER OR PROSPECTIVE MEMBER (OR THE PARENT OR GUARDIAN OF ANY OF THOSE PERSONS, IF APPLICABLE) DOES NOT AGREE TO THIS PRIVACY POLICY, OR TO ANY CHANGES WE MAY SUBSEQUENTLY MAKE TO IT, THE VISITOR, MEMBER OR PROSPECTIVE MEMBER MUST IMMEDIATELY STOP ACCESSING OUR WEBSITE AND THE EDU-BEST SERVICE.
            </p>
            <p>
              If we change our Privacy Policy, we will post any changes on our website and provide notification so that members and their parents will always be aware of what changes have been made to how personal information is collected, used and disclosed, and continued use of the EDU-BEST service shall constitute consent to the changes to the Privacy Policy.
            </p>
            <h2 className="font-bold text-2xl mt-10">What is Personal Information?</h2>
            <p>
              “Personal information” (or similar terms, such as “personally identifiable information”) is information about an identifiable individual. It includes such things as name, age, gender, educational background and performance, contact information, and other information which can be linked to an individual’s identity. It does not apply to the information of non-individuals, such as corporations and other business entities and, generally speaking, to business contact information of individuals.
            </p>
            <h2 className="font-bold text-2xl mt-10">Collection, Use and Disclosure by the EDU-BEST Service</h2>
            <p>
              When teachers register to uses the EDU-BEST Service, they are given their own profile. When signing up for the EDU-BEST Service, Edu-Best collects information submitted by and regarding the teacher, including but not limited to name, username (restricted to the school assigned email where the domain has been added to the application Allowlist), and school attended. Through the teacher account, users can view and assign assessments, and access student and class-level reporting. When teachers assign assessments, students may only access the assessments using their district assigned Google or Microsoft single sign on credentials. Schools operate in loco parentis and as such are authorized to provide consent for minor children at that school.
            </p>
            <h2 className="font-bold text-2xl mt-10">Educational Information Collected Through Use of the EDU-BEST Service</h2>
            <p>
              Edu-Best does not collect information regarding members’ educational backgrounds or other personally identifying information except their performance on the online testing provided through the EDU-BEST Service. This educational information is utilized by Edu-Best only for the purposes of providing the EDU-BEST Service to the members in question and only disclosed to the members.
            </p>
            <p>
              This educational information is also used in a non-personally identifying way to conduct research and analysis regarding the results and performance of the EDU-BEST service in order to improve and customize the EDU-BEST service and develop new products and services. This information may also be utilized and disclosed in aggregated, non-personally identifying form for statistical and research purposes.
            </p>
            <h2 className="font-bold text-2xl mt-10">Information Collected Automatically through the EDU-BEST Website</h2>
            <p>
              We automatically collect certain information from members and visitors when they access our website. This information can include, among other things:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>the Internet domain and internet protocol (IP) address from which a member or visitor accesses our website,</li>
              <li>the type of browser used (e.g., Internet Explorer, Firefox, Safari, etc.),</li>
              <li>the third-party website, if any, from which the visit originated,</li>
              <li>the operating system that the member or visitor is using (e.g., Vista, Windows XP, Macintosh OS, etc.),</li>
              <li>the domain name of the member or visitor’s Internet service provider,</li>
              <li>the search terms that a member or visitor uses on our website,</li>
              <li>the specific web pages and/or content visited, and</li>
              <li>the date, time, and duration of visits.</li>
            </ul>
            <p>
              An IP address is a number that is automatically assigned to your computer whenever you are surfing the internet. Web servers (computers that “serve up” web pages) automatically identify your computer by its IP address. When members or visitors request pages from our website, our servers typically log their IP addresses. Your IP address may personally identify you depending, in part, on the naming standards followed by your internet service provider. However, we do not use or disclose this information other than for system administration, statistical purposes, to report non-personal aggregate information to others, and to track the use of our website. Still, you may wish to ask your internet service provider about their policies and practices regarding naming standards.
            </p>
            <h2 className="font-bold text-2xl mt-10">Cookies</h2>
            <p>
              A “cookie” is a small piece of information/text sent by a web server to be stored on a user’s web browser so it can later be read back from that browser on subsequent visits to a website. A website may use cookies to store users’ preferences and to record session information, and allows communications, advertising, and Web page content to be customized according to browser type and user profile information. Visitors may be able to configure their browser to accept or reject all or some cookies, or to provide notification when a cookie is set, however, cookies must be enabled in order to access some functions on the EDU-BEST corporate website. Since each browser is different, members and visitors should check the “Help” menu of their browsers to learn how to change cookie preferences.
            </p>
            <h2 className="font-bold text-2xl mt-10">Monitoring</h2>
            <p>
              The EDU-BEST Privacy Policy only governs the privacy policies and practices of Edu-Best as it relates to the EDU-BEST service. Nonetheless, we seek to protect the integrity of our website and business and welcome any feedback about these other sites.
            </p>
            <h2 className="font-bold text-2xl mt-10">Membership De-Activation</h2>
            <p>
              Members who wish to deactivate their EDU-BEST account may do so by contacting Edu-Best in accordance with the Terms of Service. Members’ accounts may also be de-activated by Edu-Best in accordance with the Terms of Service, including where payments for membership, as applicable, are not made. Personal information relating to members who have deactivated accounts may persist in backup copies of the EDU-BEST service’s databases for a reasonable period of time but will not be available to other members of the EDU-BEST service and will be subsequently deleted except for information incorporated into aggregated (non-personally identifying) information.
            </p>
            <h2 className="font-bold text-2xl mt-10">Security</h2>
            <p>
              The EDU-BEST service uses physical and electronic precautions to protect member information stored within our databases, and we restrict access to personal information to those employees and contractors who need to access it to perform their job functions, such as our customer service personnel and technical staff. The EDU-BEST service uses email addresses, usernames, and passwords to assist in the security of member accounts. It is a member’s responsibility to protect their password as disclosure of it allows anyone to access a member’s account. Members should also take precautions to protect their email account as anyone can request that a member password be sent to the member’s the email addresses. As such, if someone else uses or controls a member’s email account, that person may be able to gain access to a member’s EDU-BEST account. To protect an EDU-BEST account, members should ensure that they do not use the “Remember Me” feature or set their browser to remember details on any public or shared computers. While we take measures to attempt to protect member’s and visitor’s information and privacy, we cannot guarantee the absolute security of personal information due to actions of other members with whom members share their pages and information and factors such as unauthorized third-party entry or use, or hardware or software failure which may compromise security.
            </p>
            <p>
              Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information and privacy, we cannot guarantee or warrant the security of any information you disclose or transmit to us online and you do so at your own risk. For any additional information about the security measures, we use on, please contact us at <a href="mailto:mprasad@Edu-Best.com" className="text-blue-500">mprasad@Edu-Best.com</a>.
            </p>
            <p>
              If you are a user of the EDU-BEST service resident in the United States, we will make any legally required disclosures of any breach of the security, confidentiality or integrity of your unencrypted electronically stored “personal data” (as defined in applicable state statutes on security breach notification) to you via email or conspicuous posting on this website in the most expedient time possible and without unreasonable delay, insofar as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the website’s data system.
            </p>
            <h2 className="font-bold text-2xl mt-10">Additional Disclosure of Personal Information</h2>
            <p>
              Except as otherwise described in this Privacy Policy, Edu-Best will not disclose your personal information to any third party unless we believe that disclosure is necessary for one of the following reasons: (1) as required by law, including to respond to a subpoena, search warrant or other legal process received by Edu-Best; (2) to enforce the Terms of Service or to protect our rights; or (3) to protect the safety of the public and members of and visitors to the EDU-BEST service. Edu-Best may also sell, transfer, or otherwise share some or all of its assets, including your personal information, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy and reserves the right to do so.
            </p>
            <h2 className="font-bold text-2xl mt-10">How to Contact Us</h2>
            <p>
              To protect your privacy and security, we take reasonable steps to verify your identity before granting access to information held by us or making corrections. If you have any questions about this Privacy Policy, the practices of this website, or your dealings with Edu-Best, please contact our privacy officer at: <a href="mailto:mprasad@Edu-Best.com" className="text-blue-500">mprasad@Edu-Best.com</a> or by mail at 5345 Mullen Bend NW, Edmonton, Alberta, Canada T6R0P9.
            </p>
            <p className="mt-10 text-gray-500">Revised: June 1, 2024</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
