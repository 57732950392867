import React, { useEffect, useRef } from "react";
import { MdVerified } from "react-icons/md";
import { useAnimation, useInView, motion } from "framer-motion";
import profile from "../../images/profile.png";
const Review = () => {
  const { innerWidth: Width } = window;
  const ref = useRef();
  const isInView = useInView(ref);
  const mainControlls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControlls.start("visible");
    }
  }, [isInView]);
  return (
    <div className="overflow-x-hidden max-w-screen font-poppins">
      <section className="">
        {Width < 631 ? (
          <>
            <div className="container px-6 py-12 mx-auto">
              <div className="grid items-center gap-4 xl:grid-cols-5">
                <div className="max-w-2xl mx-auto rounded shadow-md bg-blue-100/10 p-6 my-8 space-y-4 text-center xl:col-span-2 xl:text-left">
                  <h2 className="text-4xl font-bold text-black">
                    What Educators Have to Say
                  </h2>
                  <p className="text-black">
                    Here are some reviews from educators about our sessions and resources.
                  </p>
                </div>
                <div className="p-6 xl:col-span-3">
                  <div className="grid gap-4 md:grid-cols-2">
                    <div className="grid content-center gap-4">
                      <div className="p-6 rounded shadow-md bg-blue-200/30">
                        <p>
                          "Thank you for this valuable session - we learned so much! The teachers I spoke with were all so impressed -- your session was definitely a highlight for us at the convention this year."
                        </p>
                        <div className="flex items-center mt-4 space-x-4">
                          <img
                            src={profile}
                            alt=""
                            className="w-12 h-12 bg-center bg-cover rounded-full "
                          />
                          <div>
                            <p className="text-md font-semibold">High School Teacher</p>
                            <p className="text-sm text-gray-400 block">
                              High School
                            </p>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-6 rounded shadow-md bg-blue-200/60">
                        <p>
                          "I had the opportunity to attend a session you put on for the Mighty Peace Teachers Convention. It was very valuable and I plan to implement this into our teaching."
                        </p>
                        <div className="flex items-center mt-4 space-x-4">
                          <img
                            src={profile}
                            alt=""
                            className="w-12 h-12 bg-center bg-cover rounded-full "
                          />
                          <div>
                            <p className="text-md font-semibold">
                              High School Educator
                            </p>
                            <p className="text-sm text-gray-400">
                              High School
                            </p>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid content-center gap-4">
                      <div className="p-6 rounded shadow-md bg-blue-200/60">
                        <p>
                          "We’re really enjoying your blackline master packages; they’re really helping our students."
                        </p>
                        <div className="flex items-center mt-4 space-x-4">
                          <img
                            src={profile}
                            alt=""
                            className="w-12 h-12 bg-center bg-cover rounded-full "
                          />
                          <div>
                            <p className="text-md font-semibold">
                              Department Head, Junior and Senior High Humanities
                            </p>
                            <p className="text-sm dark:text-gray-400">High School</p>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-6 rounded shadow-md bg-blue-200/30">
                        <p>
                          "We would like to go ahead with the 5-year license to the RCAT. It has been a fantastic tool and incredibly useful for collecting data for the school."
                        </p>
                        <div className="flex items-center mt-4 space-x-4">
                          <img
                            src={profile}
                            alt=""
                            className="w-12 h-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                          />
                          <div>
                            <p className="text-md font-semibold">
                              Junior High School Administrator
                            </p>
                            <p className="text-sm dark:text-gray-400">Junior High School</p>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container px-6 py-12 mx-auto">
              <div className="grid items-center gap-4 xl:grid-cols-5">
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                  }}
                  initial="hidden"
                  animate={mainControlls}
                  transition={{ duration: 0.5, delay: 0.25 }}
                  className="max-w-2xl mx-auto rounded shadow-md bg-blue-100/10 p-6 my-8 space-y-4 text-center xl:col-span-2 xl:text-left"
                >
                  <h2 className="text-4xl font-bold text-black">
                    What Educators Have to Say
                  </h2>
                  <p className="text-black">
                    Here are some reviews from educators about our sessions and resources.
                  </p>
                </motion.div>
                <div className="p-6 xl:col-span-3">
                  <div className="grid gap-4 md:grid-cols-2">
                    <div className="grid content-center gap-4">
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, x: -75, y: -45 },
                          visible: { opacity: 1, x: 0, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.6, delay: 0.15 }}
                        className="p-6 rounded shadow-md bg-blue-200/30"
                      >
                        <p>
                          "I know it is late in the year, and we are tired, but even if you are not staying online next year, these are great resources that give you valuable information. I just gave my second test of the year today and it went off without a hitch."
                        </p>
                        <div className="flex justify-between items-center mt-4 space-x-4">
                          <div className="flex">
                            <img
                              src={profile}
                              alt=""
                              className="w-12 h-12 bg-center bg-cover rounded-full "
                            />
                            <div className="flex-col ml-4">
                              <p className="text-lg font-semibold block">
                                Online School Teacher
                              </p>
                              <p className="text-sm text-gray-400 block">
                                Online School
                              </p>
                            </div>
                          </div>

                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, x: -75, y: 45 },
                          visible: { opacity: 1, x: 0, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.6, delay: 0.35 }}
                        className="p-6 rounded shadow-md bg-blue-200/60"
                      >
                        <p>
                          "The following were comments on the program: liked the flexibility that different tests could be used for differentiated learners, liked that the testing could be used as a benchmark, liked the reports that provided clear areas for skill improvement for individual students or a whole class."
                        </p>
                        <div className="flex items-center justify-between mt-4 space-x-4">
                          <div className="flex">
                            <img
                              src={profile}
                              alt=""
                              className="w-12 h-12 bg-center bg-cover rounded-full "
                            />
                            <div className="flex-col sm:ml-2 ">
                              <p className="text-lg font-semibold block">
                                School District Administrator
                              </p>
                              <p className="text-sm text-gray-400 block">
                                School District
                              </p>
                            </div>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </motion.div>
                    </div>
                    <div className="grid content-center gap-4">
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, x: 75, y: -45 },
                          visible: { opacity: 1, x: 0, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.7, delay: 0.55 }}
                        className="p-6 rounded shadow-md bg-blue-200/60"
                      >
                        <p>
                          "We are finding the RCAT very useful this year, especially since we are trying to go paperless as much as possible. We are finding the data useful and the program easy to use."
                        </p>
                        <div className="flex justify-between items-center mt-4 space-x-4">
                          <div className="flex">
                            <img
                              src={profile}
                              alt=""
                              className="w-12 h-12 bg-center bg-cover rounded-full "
                            />
                            <div className="flex-col ml-4">
                              <p className="text-lg font-semibold block">
                                Junior High School Teacher
                              </p>
                              <p className="text-sm text-gray-400 block">
                                Junior High School
                              </p>
                            </div>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, x: 75, y: 45 },
                          visible: { opacity: 1, x: 0, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.8, delay: 0.75 }}
                        className="p-6 rounded shadow-md bg-blue-200/30"
                      >
                        <p>
                          "Thank you so much for your resources; they are phenomenal!"
                        </p>
                        <div className="flex justify-between items-center mt-4 space-x-4">
                          <div className="flex">
                            <img
                              src={profile}
                              alt=""
                              className="w-12 h-12 bg-center bg-cover rounded-full "
                            />
                            <div className="flex-col ml-4">
                              <p className="text-lg font-semibold block">
                                ELL Designate/ELA Teacher
                              </p>
                              <p className="text-sm text-gray-400 block">
                                High School
                              </p>
                            </div>
                          </div>
                          <div className="flex">
                            <MdVerified
                              size={Width < 631 ? 35 : 40}
                              className="text-green-400 ml-2"
                            />
                          </div>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      <div className="w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
    </div>
  );
};

export default Review;