import React, { useEffect, useRef } from "react";
import Navbar from "../../Navigation/Navbar";
import Footer from "../../Footer/Footer";
import { useAnimation, useInView, motion } from "framer-motion";
import TeamImage from '../../../images/Features/fb22.png';
import { RxLinkedinLogo } from "react-icons/rx";
import profile from "../../../images/Features/profile.jpg";

const About = () => {
  const ref = useRef();
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div>
      <Navbar />
      <div className="max-w-screen-xl mx-auto overflow-hidden font-poppins">
        <div className="bg-gradient-to-b from-gray-100/10 to-gray-200/50 pt-20">
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: -75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            className="text-center"
          >
            <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-emerald-500 font-extrabold text-4xl">
              Who are we and what are we so good at?
            </h1>
          </motion.div>
          <div className="flex flex-wrap justify-around items-start mt-2">
            <motion.div
              ref={ref}
              variants={{
                hidden: { opacity: 0, x: -75 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="w-full sm:w-1/2 p-7"
            >
              <img
                alt="About the team"
                src={TeamImage}
                className="rounded-2xl shadow-lg transform transition duration-300 hover:scale-105"
              />
            </motion.div>
            <motion.div
              ref={ref}
              variants={{
                hidden: { opacity: 0, x: 75 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="w-full sm:w-1/2 p-5 text-center"
            >
              <h1 className="text-xl sm:text-4xl font-extrabold text-blue-500">
                "Inspired by Results"
              </h1>
              <h1 className="text-xl sm:text-4xl font-extrabold text-blue-500">
                "Driven by Data"
              </h1>
            </motion.div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between px-5 sm:px-20 py-10">
            <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-emerald-500 font-extrabold text-4xl">
              Welcome to Edu-Best, where Teachers work collaboratively at Edu-Best to create useful resources for teachers and students.
            </h1>
          </div>
        </div>
        <div className="py-5 bg-gradient-to-r from-blue-400 to-violet-500"></div>
        <div className="py-10">
          <div className="container mx-auto text-center">
            <p className="text-gray-500 text-lg font-normal">
              Meet Brigitta Goerres – President and CEO
            </p>
            <h1 className="text-4xl text-gray-800 font-extrabold">
              Leading a team of talented and passionate education professionals!
            </h1>
          </div>
          <div className="w-full bg-gray-100 px-10 pt-10">
            <div className="container mx-auto">
              <div className="flex items-center justify-center">
                <div className="w-full lg:w-3/4">
                  <div className="rounded overflow-hidden shadow-md bg-white p-10">
                    <div className="flex flex-col items-center">
                      <img
                        src={profile}
                        alt="Brigitta Goerres"
                        className="rounded-full object-cover h-32 w-32 shadow-md mb-4"
                      />
                      <div className="text-center">
                        <h2 className="font-bold text-3xl pb-1">Brigitta Goerres</h2>
                        <p className="text-gray-800 text-sm">Educator</p>
                      </div>
                      <p className="text-gray-600 text-base pt-3 font-normal text-left">
                        Brigitta Goerres has been involved in education for over 35 years, starting her career as a classroom teacher immediately after graduating from the University of Alberta. Her professional journey led her to the Alberta Ministry of Education, after which she dedicated a decade to being the executive director of curriculum at an educational company. There she developed both print and digital study tools for students, teachers, and parents.
                        <br /><br />
                        After completing her graduate studies in Curriculum Studies at the University of Alberta, Brigitta worked with various assessment companies across North America and Europe, further broadening her expertise and impact in the field. Currently, Brigitta combines her experiences and has stepped back into the classroom, this time to an audience of diversified learners with unique backgrounds and goals.
                        <br /><br />
                        She has founded a consulting business to collaborate and share ideas and methods that will give all learners the opportunity to reach their highest level of achievement, as well as to inspire and motivate teachers. This initiative is part of her commitment to her own lifelong learning journey.
                        <br /><br />
                        Brigitta has authored 15 resources focused on reading comprehension success and conducts professional development workshops and courses for teachers at all levels. The Classroom Ready series represents the culmination of her experience. Working in partnership with educators and school districts, Brigitta Goerres has worked to develop the online tool RCAT, which serves as a platform for formative reading comprehension assessment, reporting, and monitoring of students’ performance.
                        <br /><br />
                        Brigitta’s journey in education is marked by extraordinary milestones, a journey she continues to pursue with excitement and passion.
                      </p>
                      <div className="w-full flex justify-center pt-5 pb-5">
                        <a href="https://www.linkedin.com/in/brigitta-goerres-96623222/" className="mx-5">
                          <RxLinkedinLogo
                            size={25}
                            className="text-gray-500 hover:text-purple-500"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500 mx-auto my-10"></div>
        <div className="h-[40rem] sm:mt-20 mt-[20%] mb-20">
          <h1 className="flex justify-center font-extrabold text-center sm:text-4xl text-2xl">
            Where you can find us
          </h1>
          <div className="flex justify-center mt-20" id="maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2373.151094371216!2d-113.45559562325889!3d53.50149627233473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a019633b0bd1e5%3A0x298d2ffced218a7f!2s8403%20Coronet%20Rd%2C%20Edmonton%2C%20AB%20T6E%204N7!5e0!3m2!1sen!2sca!4v1717608763178!5m2!1sen!2sca"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-screen sm:mx-36 mx-10 h-[30rem]"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
