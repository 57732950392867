import React, { useEffect, useRef } from "react";
import Card from "./Card";
import image1 from "../../images/Price/1.png"; // Assuming this image is relevant
import image2 from "../../images/Price/2.png"; // Assuming this image is relevant
import image3 from "../../images/Price/3.png"; // Assuming this image is relevant
import { useAnimation, useInView, motion } from "framer-motion";

const CardComponent = () => {
  const { innerWidth: Width } = window;
  const ref = useRef();
  const isInView = useInView(ref);
  const mainControls = useAnimation();
  
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div className="max-w-screen overflow-x-hidden font-poppins">
      <div className="w-full py-[10rem] px-4 bg-white -mt-28">
        <div className="flex justify-center">
          <h2 className="text-3xl font-bold text-center sm:text-5xl mt-[6%] text-black">
            Our Educational Packages
          </h2>
        </div>
        <p className="flex text-xl justify-center sm:mb-24 mb-16 text-center mt-6 sm:mt-0 ">
          Discover the optimal package to enhance reading comprehension in your educational setting.
        </p>
        {/* cards */}
        <>
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-3 gap-8">
            {Width < 631 ? (
              <>
                {/* Assuming this condition dynamically renders smaller or less detailed cards for mobile view */}
              </>
            ) : (
              <>
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  <Card
                    image={image1}
                    Package={"RCAT Access"}
                    Price={"Custom Pricing"}
                    Feature1={"Digital Assessments for Grades 3-12"}
                    Feature2={"Printed Review Materials"}
                    Feature3={"Secure Online Access"}
                    Feature4={"Aligned with Educational Standards"}
                    Feature5={"Detailed Analytical Reports"}
                    Feature6={"Full Reproducibility Rights"}
                    iconColor1={"text-blue-500"}
                    iconColor2={"text-blue-500"}
                    iconColor3={"text-blue-500"}
                    iconColor4={"text-blue-500"}
                    iconColor5={"text-blue-500"}
                    iconColor6={"text-blue-500"}
                    buttonBg={"bg-blue-400"}
                    hoverBgButton={"hover:bg-blue-300"}
                  />
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ duration: 0.5, delay: 0.65 }}
                >
                  <Card
                    image={image2}
                    Package={"Teacher Workshops"}
                    Price={"Contact for Pricing"}
                    Feature1={"Professional Development Sessions"}
                    Feature2={"Focus on Reading Comprehension"}
                    Feature3={"Tailored to School Needs"}
                    Feature4={"Led by Experienced Educators"}
                    Feature5={"Interactive and Practical"}
                    Feature6={"Certification Available"}
                    iconColor1={"text-purple-500"}
                    iconColor2={"text-purple-500"}
                    iconColor3={"text-purple-500"}
                    iconColor4={"text-purple-500"}
                    iconColor5={"text-purple-500"}
                    iconColor6={"text-purple-500"}
                    buttonBg={"bg-purple-400"}
                    hoverBgButton={"hover:bg-purple-500"}
                    buttonTextColor={"text-white"}
                    standOutBg={"bg-purple-100/30"}
                    marginMiddle={"md:my-6"}
                  />
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  <Card
                    image={image2}
                    Package={"Student Workbooks"}
                    Price={"Volume Pricing"}
                    Feature1={"Curated Content for Grades 3-12"}
                    Feature2={"Narratives, Informational, and Poetic Texts"}
                    Feature3={"Over 300 Questions per Grade"}
                    Feature4={"Skills-Based Approach"}
                    Feature5={"Comprehensive Answer Keys"}
                    Feature6={"Print and Digital Formats"}
                    iconColor1={"text-green-500"}
                    iconColor2={"text-green-500"}
                    iconColor3={"text-green-500"}
                    iconColor4={"text-green-500"}
                    iconColor5={"text-green-500"}
                    iconColor6={"text-green-500"}
                    buttonBg={"bg-green-300"}
                    hoverBgButton={"hover:bg-green-200"}
                  />
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                <Card
                  image={image3}
                  Package={"READING COMPREHENSION SUCCESS - BLACK LINE MASTER PACKAGES"}
                  Price={"Volume Pricing"}
                  Feature1={"Available for grades 3 to 12"}
                  Feature2={"Narratives, Informational, and Poetic Texts"}
                  Feature3={"Over 300 well performing and discerning assessment questions"}
                  Feature4={"Answer key with detailed explanations"}
                  Feature5={"Alignment to the reading comprehension skills assessed"}
                  Feature6={"Fully reproducible in print or digital format"}
                  Feature7={"License for the content will not expire"}
                  iconColor1={"text-green-500"}
                  iconColor2={"text-green-500"}
                  iconColor3={"text-green-500"}
                  iconColor4={"text-green-500"}
                  iconColor5={"text-green-500"}
                  iconColor6={"text-green-500"}
                  iconColor7={"text-green-500"}
                  buttonBg={"bg-green-300"}
                  hoverBgButton={"hover:bg-green-200"}
                />

                </motion.div>
              </>
            )}
          </div>
        </>
        {/* cards end */}
      </div>
    </div>
  );
};

export default CardComponent;
